<template>
  <div class="lg:my:12 relative my-4 md:my-8">
    <div
      class="mx-auto max-w-screen-xl bg-opacity-0 px-5 pb-8 lg:py-10 xl:px-0"
      :class="{ 'mt-12': data?.image?.borderShape === 'landscape' }">
      <div :class="{ 'grid items-center gap-8 lg:grid-cols-12': !!data?.image }">
        <div v-if="data?.image" class="col-span-6 flex justify-center lg:order-last lg:row-auto lg:justify-end">
          <SharedSimpleImage :data="data.image" class="max-w-[70%] md:max-w-[45%] lg:max-w-heroImg" />
        </div>
        <div class="relative col-span-6 sm:mb-6 lg:mb-0 lg:text-left">
          <div v-for="comp in data.dynamic_hero_component?.data.attributes.components" :key="comp.id" class="w-full">
            <Component
              :is="getResolvedComponent(comp.__component)"
              :data="comp"
              class="text-gray-900"
              :is-dynamic-hero="true" />
          </div>
        </div>
      </div>
    </div>
    <ImageStrapiImage
      v-if="data.background?.data"
      :data="data.background.data"
      class="absolute -left-1/4 top-[40%] z-negative-2 md:-left-1/4 md:top-[25%] md:max-w-[80%] lg:-left-20 lg:-top-5 lg:max-w-[45%] xl:max-w-[600px]" />
  </div>
</template>

<script setup lang="ts">
import type { DynamicHero } from "~/types/strapi/components/block/interfaces/DynamicHero";

const getResolvedComponent = (componentName: string) => {
  switch (componentName) {
    case "shared.title":
      return resolveComponent("LazySharedTitle");
    case "shared.subtitle":
      return resolveComponent("LazySharedSubtitle");
    case "shared.list-item":
      return resolveComponent("LazySharedListItem");
    case "shared.button":
      return resolveComponent("LazySharedButton");
    case "shared.icon":
      return resolveComponent("LazySharedIcon");
  }
};

defineProps<{
  data: DynamicHero;
}>();
</script>

<style scoped></style>
